import { useCallback, useContext, useRef } from 'react'
import { ParamsContext } from '~/contexts/ParamsContext'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { generateHash } from '~/utils/generateHash'
import { facebookMetaGraph } from '~/services/api'
import { convertToSlug } from '~/utils/generateSlug'
import { generateUuid } from '~/utils/generateUUID'

const generateCustomData = (totalValue: number, contents: any[], extraFields = {}) => ({
  value: totalValue,
  currency: 'BRL',
  contents: contents,
  content_type: 'product',
  ...extraFields,
})

const createEventData = (
  eventName: string,
  cartData: any,
  partnerData: any,
  studentData: any,
  customData: any,
  clientIpAddress: string,
  clientUserAgent: string,
) => ({
  event_name: eventName === 'Purchase' && partnerData?.accessToken ? 'PaymentGenerated' : eventName,
  event_id: `${cartData?.PartnerId}-${convertToSlug(partnerData?.partnerName)}-${generateUuid()}`,
  event_time: Math.floor(Date.now() / 1000),
  action_source: 'website',
  user_data: {
    ...(studentData?.basicInfoData?.email && { em: generateHash(studentData?.basicInfoData?.email) }),
    ...(studentData?.basicInfoData?.fullName && { fn: generateHash(studentData?.basicInfoData?.fullName) }),
    ...(studentData?.basicInfoData?.phone && { ph: generateHash(studentData?.basicInfoData?.phone) }),
    ...(studentData?.basicInfoData?.fn && { ln: generateHash(studentData?.basicInfoData?.fn) }),
    ...(studentData?.basicInfoData?.ln && { ln: generateHash(studentData?.basicInfoData?.ln) }),
    ...(studentData?.basicInfoData?.birthDate && { db: generateHash(studentData?.basicInfoData?.birthDate) }),
    ...(studentData?.basicInfoData?.city && { ct: generateHash(studentData?.basicInfoData?.city) }),
    ...(studentData?.basicInfoData?.state && { st: generateHash(studentData?.basicInfoData?.state) }),
    ...(studentData?.basicInfoData?.zipcode && { zp: generateHash(studentData?.basicInfoData?.zipcode) }),
    ...(studentData?.basicInfoData?.country && { country: generateHash(studentData?.basicInfoData?.country) }),
    client_ip_address: clientIpAddress,
    client_user_agent: clientUserAgent,
  },
  event_source_url: typeof window !== 'undefined' ? window.location.href : '',
  custom_data: customData,
})

export const useAnalytics = () => {
  const { clientIpAddress, clientUserAgent } = useContext(CheckoutContext)
  const { cartData, partnerData } = useContext(ParamsContext)
  const initializedPixels = useRef(new Set())

  const sendEvent = useCallback(
    async (eventName: string, studentData: any = {}) => {
      const totalValue = cartData?.courses?.reduce((sum, course) => sum + Number(course.price || 0), 0) || 0
      const contents =
        cartData?.courses?.map((course) => ({
          id: course.CourseId || '',
          quantity: 1,
          price: course.price || 0,
        })) || []

      let customData = {}
      switch (eventName) {
        case 'Purchase':
          customData = generateCustomData(totalValue, contents, {
            payment_method: studentData?.paymentMethod || 'unknown',
            payment_status: studentData?.paymentStatus || 'pending',
          })
          break
        default:
          customData = generateCustomData(totalValue, contents)
      }

      const eventData = {
        data: [createEventData(eventName, cartData, partnerData, studentData, customData, clientIpAddress, clientUserAgent)],
      }

      if (typeof window !== 'undefined' && window.fbq) {
        partnerData?.trackingData?.forEach(async (tracking: any) => {
          const pixelId = tracking?.credentials?.pixelId

          if (!initializedPixels.current.has(pixelId)) {
            window.fbq('init', pixelId)
            initializedPixels.current.add(pixelId)
          }
        })

        window.fbq(
          'track',
          eventName,
          { userData: eventData?.data[0]?.user_data, customData },
          { eventID: eventData.data[0].event_id },
        )
      }

      if (Array.isArray(partnerData?.trackingData) && partnerData.trackingData.length > 0) {
        await Promise.all(
          partnerData?.trackingData?.map(async (tracking: any) => {
            const paymentMethod = studentData?.basicInfoData?.paymentMethod
            const { credentials, enabledEvents } = tracking
            const { accessToken, testEventCode } = credentials

            const metaGraphData: Record<string, any> = {
              ...eventData,
              test_event_code: testEventCode,
            }

            if (eventName == 'Purchase' && accessToken && paymentMethod && enabledEvents?.[paymentMethod]) {
              metaGraphData.paymentMethod = paymentMethod
            }
            await facebookMetaGraph(credentials, { ...eventData, test_event_code: testEventCode })
          }),
        )
      }
    },
    [cartData, clientIpAddress, clientUserAgent, initializedPixels, partnerData],
  )

  return { sendEvent }
}
