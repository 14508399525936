import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useState, useRef, MutableRefObject } from 'react'
import { useRouter } from 'next/router'
import { ICourses, ICoursesData, IQueryParams } from 'types'
import { gtagAddToCart } from '~/utils/gtag/gtagAddToCart'
import { gtagRemoveFromCart } from '~/utils/gtag/gtagRemoveFromCart'

interface ICoursesContextData {
  selectClass: (selectedClass: IClass) => void
  selectedClasses: IClass[] | null
  setSelectedClasses: Dispatch<SetStateAction<IClass[] | null>>
  coursesData?: ICoursesData
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  getParams: () => IQueryParams
  modalRef: MutableRefObject<IModalProps | undefined>
  openModal: () => void
  closeModal: () => void
  enteredText: string
  setEnteredText: Dispatch<SetStateAction<string>>
  unavailableClickedCourseObject: ICourses | null
  setUnavailableClickedCourseObject: Dispatch<SetStateAction<ICourses | null>>
}

interface CountdownProviderProps {
  children: ReactNode
}

interface IClass {
  classId: number
  courseId: number
  courseClassName?: string
  price?: string | number
}

export interface IModalProps extends HTMLElement {
  openModalFn: () => void
  closeModalFn: () => void
}

export const CoursesContext = createContext({} as ICoursesContextData)

export function CoursesProvider({ children }: CountdownProviderProps) {
  const [unavailableClickedCourseObject, setUnavailableClickedCourseObject] = useState<null | ICourses>(null)
  const router = useRouter()
  const { token, campaign, classes, seller, saleId } = router.query
  const [selectedClasses, setSelectedClasses] = useState<IClass[] | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [enteredText, setEnteredText] = useState('')
  const modalRef = useRef<IModalProps>()

  const openModal = () => {
    modalRef?.current?.openModalFn()
  }

  const closeModal = () => {
    modalRef?.current?.closeModalFn()
  }

  /**
   * @description Get params from url
   * @param {boolean=} isFirstLoad
   * @returns {IQueryParams} params
   */
  const getParams = useCallback(
    (isFirstLoad?: boolean) => {
      const params: IQueryParams = {}

      if (classes?.length) {
        const sortedClasses = (classes as string)
          .split(',')
          .map((c) => parseInt(c, 10))
          .sort((a, b) => a - b)
          .join(',')

        params.classes = sortedClasses
      }

      if (!isFirstLoad && selectedClasses != null) {
        const courseClasses = selectedClasses.map((course) => course.classId)
        params.classes = courseClasses?.join(',').toString()
      }

      if (token) {
        params.token = token.toString()
      }

      if (campaign) {
        params.campaign = campaign.toString()
      }

      if (!params?.classes?.length) {
        delete params.classes
      }

      if (seller) {
        params.seller = seller.toString()
      }

      if (saleId) {
        params.saleId = saleId.toString()
      }

      return params
    },
    [selectedClasses, token, classes, campaign, seller, saleId],
  )

  const removeAllClassesByCourseId = useCallback(
    (indicatedClass: IClass[], indicatedCourseId: number) =>
      indicatedClass.filter((selectedClass: IClass) => selectedClass.courseId !== indicatedCourseId),
    [],
  )

  const getSelectClass = useCallback(
    ({ classId, courseId }: IClass) => {
      if (selectedClasses != null && selectedClasses.some((selectedClass: IClass) => selectedClass.courseId === courseId)) {
        if (selectedClasses.some((selectedClass: IClass) => selectedClass.classId === classId)) {
          return removeAllClassesByCourseId(selectedClasses, courseId)
        }

        return [...removeAllClassesByCourseId(selectedClasses, courseId), { classId, courseId }]
      }

      if (selectedClasses != null) {
        return [...selectedClasses, { classId, courseId }]
      }

      return [{ classId, courseId }]
    },
    [selectedClasses, removeAllClassesByCourseId],
  )

  /**
   * @description Update selectClasses state and url params based on selectedClasses
   * @param {IClass.classId} classId
   * @param {IClass.courseId} courseId
   * @returns {void} void
   */
  const selectClass = useCallback(
    ({ classId, courseId, courseClassName, price }: IClass) => {
      setSelectedClasses(getSelectClass({ classId, courseId }))

      const eventObject = {
        CourseClassId: classId,
        CourseId: courseId,
        className: courseClassName,
        courseName: '',
        price: `${price}`,
        removable: true,
      }

      const isInCart = selectedClasses?.some((selectedClass: IClass) => selectedClass.classId === classId)

      if (isInCart) {
        gtagRemoveFromCart(price, eventObject)
        return
      }

      gtagAddToCart(price, eventObject)
    },
    [setSelectedClasses, getSelectClass, selectedClasses],
  )

  return (
    <CoursesContext.Provider
      value={{
        selectClass,
        selectedClasses,
        setSelectedClasses,
        isLoading,
        setIsLoading,
        getParams,
        modalRef,
        openModal,
        closeModal,
        enteredText,
        setEnteredText,
        unavailableClickedCourseObject,
        setUnavailableClickedCourseObject,
      }}
    >
      {children}
    </CoursesContext.Provider>
  )
}
