export function convertToSlug(text: string | undefined) {
  if (!text) {
    return
  }
  return text
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]/g, '')
}
