import { memo, useEffect, useMemo, useRef } from 'react'
import 'react-multi-carousel/lib/styles.css'
import { IPaymentForms } from 'types'
import { PaymentMethodCard } from '~/components/molecules'
import { usePaymentMethodCards } from './hooks'
import * as S from './style'

interface IPaymentMethodCards {
  hasInstability?: boolean
}

const PaymentMethodCards = ({ hasInstability }: IPaymentMethodCards) => {
  const { isLoadingData, paymentInformationData, handlePaymentMethod, chosenPaymentMethod } = usePaymentMethodCards()

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef?.current) {
      if (chosenPaymentMethod === 'CourseFinancing') {
        containerRef.current.scrollLeft = containerRef.current.scrollWidth
      }

      if (chosenPaymentMethod === 'CreditCard') {
        containerRef.current.scrollLeft = 0
      }
    }
  }, [chosenPaymentMethod])

  const renderPaymentMethodCards = useMemo(() => {
    const isPixEnabled = paymentInformationData?.response?.paymentMethods?.includes('Pix')

    const allPaymentMethods: IPaymentForms[] = isPixEnabled
      ? ['CreditCard', 'Pix', 'CourseFinancing', 'Boleto']
      : ['CreditCard', 'Boleto', 'CourseFinancing']

    const haveReason = paymentInformationData?.response?.reason?.length

    const courseFinancingFragment = haveReason && (
      <PaymentMethodCard
        paymentMethod={'CourseFinancing'}
        isDisabled={true}
        isLoading={false}
        isActive={false}
        reason={paymentInformationData?.response?.reason || 'Meio de pagamento indisponível'}
        onClick={() => {}}
      />
    )
    const availablePaymentMethods = allPaymentMethods.filter((method) =>
      paymentInformationData?.response?.paymentMethods.includes(method),
    )

    return (
      <>
        {availablePaymentMethods.map((currentPaymentMethod) => {
          const isCreditCardAndBoletoDisabled =
            (hasInstability && currentPaymentMethod === 'Boleto') || (hasInstability && currentPaymentMethod === 'CreditCard')
          const paymentMethodCardDisabled =
            isCreditCardAndBoletoDisabled ||
            !paymentInformationData?.response?.paymentMethods?.includes(currentPaymentMethod) ||
            paymentInformationData?.response?.paymentMethodsExt?.find((ext) => ext.method === currentPaymentMethod)?.active ===
              false
          const reason =
            paymentInformationData?.response?.paymentMethodsExt?.find((ext) => ext.method === currentPaymentMethod)?.reason ||
            ''
          return (
            <PaymentMethodCard
              key={currentPaymentMethod}
              reason={reason}
              paymentMethod={currentPaymentMethod}
              isActive={chosenPaymentMethod === currentPaymentMethod}
              isDisabled={paymentMethodCardDisabled}
              isLoading={isLoadingData}
              onClick={() => {
                handlePaymentMethod(currentPaymentMethod)
              }}
            />
          )
        })}
        {courseFinancingFragment}
      </>
    )
  }, [chosenPaymentMethod, paymentInformationData, handlePaymentMethod, isLoadingData, hasInstability])

  return <S.PaymentMethodCardsWrapper ref={containerRef}>{renderPaymentMethodCards}</S.PaymentMethodCardsWrapper>
}

export default memo(PaymentMethodCards)
