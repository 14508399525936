import { useContext } from 'react'
import { CheckoutContext } from '~/contexts/CheckoutContext'
import { ParamsContext } from '~/contexts/ParamsContext'

export const useCheckoutSuccessByMethod = () => {
  const { chosenPaymentMethod, checkoutSuccessObject, basicInfoData } = useContext(CheckoutContext)
  const { cartData } = useContext(ParamsContext)

  return {
    chosenPaymentMethod: chosenPaymentMethod || cartData?.paymentMethod?.method,
    checkoutSuccessObject,
    basicInfoData,
  }
}
