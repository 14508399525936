import { useRouter } from 'next/router'
import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'
import {
  IBasicInfoResponse,
  ICheckoutSuccessResponse,
  ICourseFinancingInstallment,
  IExistingRequest,
  IPaymentData,
  IPaymentForms,
  IReadProgress,
  IStep,
  IVerificationInformations,
  IWriteSinglePageCheckout,
} from 'types'
import { hiddenHeaderCheckoutScreens } from '~/constants/hiddenHeaderCheckoutScreens'
import useSessionStorage from '~/hooks/useSessionStorage'
import { getExclusiveSale, readPaymentInfo } from '~/services/api'
import { changeRouteKeepParams } from '~/utils/changeRouteKeepParams'
import { pageview } from '~/utils/gtag'
import { gtagBeginCheckout } from '~/utils/gtag/gtagBeginCheckout'

interface ICheckoutContextData {
  setSelectedPartnerConditionId: Dispatch<SetStateAction<number | null>>
  setIsInCourseFinancingReviewScreen: Dispatch<SetStateAction<boolean>>
  setPaymentInformationData: Dispatch<SetStateAction<IPaymentData | null>>
  setChosenPaymentMethod: Dispatch<SetStateAction<IPaymentForms | null>>
  setBasicInfoData: Dispatch<SetStateAction<IBasicInfoResponse>>
  setVerificationInfo: Dispatch<SetStateAction<IVerificationInformations>>
  setIsLoadingData: Dispatch<SetStateAction<boolean>>
  setIsSendingData: Dispatch<SetStateAction<boolean>>
  setIsChecked: Dispatch<SetStateAction<boolean>>
  setStep: Dispatch<SetStateAction<IStep>>
  setCheckoutSuccessObject: Dispatch<SetStateAction<ICheckoutSuccessResponse | null>>
  selectedPartnerConditionId: number | null
  isInCourseFinancingReviewScreen: boolean
  paymentInformationData: IPaymentData | null
  chosenPaymentMethod: IPaymentForms | null
  verificationInfo: IVerificationInformations
  basicInfoData: IBasicInfoResponse
  isLoadingData: boolean
  isSendingData: boolean
  isChecked: boolean
  step: IStep
  chosenCourseFinancingInstallment: ICourseFinancingInstallment | undefined
  checkoutSuccessObject: ICheckoutSuccessResponse | null
  progressBar: IReadProgress | null
  setProgressBar: Dispatch<SetStateAction<IReadProgress | null>>
  setExistingRequestsData: Dispatch<SetStateAction<IExistingRequest[] | null>>
  existingRequestsData: IExistingRequest[] | null
  isPaymentInfoLoading: boolean
  setIsPaymentInfoLoading: Dispatch<SetStateAction<boolean>>
  isRedirecting: boolean
  setIsRedirecting: Dispatch<SetStateAction<boolean>>
  selectedUpfront: number | null
  setSelectedUpfront: Dispatch<SetStateAction<number | null>>
  partnerId: number | null
  setPartnerId: Dispatch<SetStateAction<number | null>>
  isHeaderCheckoutShown: boolean
  setAlreadyHasActiveProductType: Dispatch<SetStateAction<boolean | null>>
  alreadyHasActiveProductType: boolean | null
  inPageError: IWriteSinglePageCheckout['response'] | null
  setInPageError: Dispatch<SetStateAction<IWriteSinglePageCheckout['response'] | null>>
  clientIpAddress: string | null
  setClientIpAddress: Dispatch<SetStateAction<string | null>>
  clientUserAgent: string | null
  setClientUserAgent: Dispatch<SetStateAction<string | null>>
}

interface CheckoutProviderProps {
  children: ReactNode
}

export const CheckoutContext = createContext({} as ICheckoutContextData)

export const CheckoutProvider = ({ children }: CheckoutProviderProps) => {
  const [saleId, setSaleId] = useSessionStorage<string>('saleId', '')
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [step, setStep] = useSessionStorage<IStep | null>('step', null)
  const [verificationInfo, setVerificationInfo] = useState<IVerificationInformations>({ email: '', phone: '' })
  const [basicInfoData, setBasicInfoData] = useState<IBasicInfoResponse>({
    CPF: '',
    email: '',
    fullName: '',
    fullSocialName: '',
    phone: '',
    birthDate: '',
    zipcode: '',
    RG: '',
    state: '',
    city: '',
    district: '',
    street: '',
    streetNumber: '',
    complement: '',
  })

  const [chosenPaymentMethod, setChosenPaymentMethod] = useSessionStorage<IPaymentForms | null>('chosenPaymentMethod', null)
  const [selectedPartnerConditionId, setSelectedPartnerConditionId] = useState<number | null>(null)
  const [selectedUpfront, setSelectedUpfront] = useState<number | null>(null)
  const [paymentInformationData, setPaymentInformationData] = useSessionStorage<IPaymentData | null>(
    'paymentInformationData',
    null,
  )
  const [isInCourseFinancingReviewScreen, setIsInCourseFinancingReviewScreen] = useState<boolean>(false)
  const [checkoutSuccessObject, setCheckoutSuccessObject] = useState<ICheckoutSuccessResponse | null>(null)
  const [progressBar, setProgressBar] = useState<IReadProgress | null>(null)
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
  const [isSendingData, setIsSendingData] = useState<boolean>(false)
  const [isPaymentInfoLoading, setIsPaymentInfoLoading] = useState(true)
  const [partnerId, setPartnerId] = useState<number | null>(null)
  const [alreadyHasActiveProductType, setAlreadyHasActiveProductType] = useSessionStorage<boolean | null>(
    'alreadyHasActiveProductType',
    null,
  )

  const [existingRequestsData, setExistingRequestsData] = useSessionStorage<IExistingRequest[] | null>('existingRequests', null)

  const [inPageError, setInPageError] = useState<IWriteSinglePageCheckout['response'] | null>(null)

  const [clientIpAddress, setClientIpAddress] = useState<string | null>(null)
  const [clientUserAgent, setClientUserAgent] = useState<string | null>(null)

  const chosenCourseFinancingInstallment =
    paymentInformationData?.response?.CourseFinancing?.options
      ?.find(({ upfrontAmountInPercent }) => upfrontAmountInPercent === selectedUpfront)
      ?.installments?.find((condition) => condition?.PartnerConditionId === selectedPartnerConditionId) ||
    paymentInformationData?.response?.CourseFinancing?.options?.[0]?.installments?.find(
      (condition) => condition?.PartnerConditionId === selectedPartnerConditionId,
    )

  const isHeaderCheckoutShown = useMemo(() => {
    return !hiddenHeaderCheckoutScreens.includes(step)
  }, [step])

  const router = useRouter()

  useEffect(() => {
    if (router?.query?.saleId) {
      setSaleId(router?.query?.saleId as string)
    }
  }, [router?.query?.saleId])

  useEffect(() => {
    if (saleId && basicInfoData.CPF === '' && (step === 'preRegistration' || !step)) {
      ;(async () => {
        const response = await getExclusiveSale(saleId)
        if (response?.sale?.CPF && response.error === false) {
          setBasicInfoData((prev) => ({ ...prev, CPF: response?.sale?.CPF || '' }))
        }
      })()
    }
  }, [saleId])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // this scrolls to the top of the page when step changes
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })

      // this forces facebook pixel to send a new PageView event
      const url = new URL(window.location.href)
      url.searchParams.set('step', step)
      window.history.replaceState(null, '', url.href)

      if (window.location.pathname.includes('/checkout')) {
        pageview(`/checkout/${step}`)
      }

      if (step === 'paymentSelection') {
        gtagBeginCheckout()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [step])

  // this handles when user clicks on the back button
  // if there isn't a previous screen, it will redirect to the courses page
  // if there is a previous screen and the user can back, it will redirect to the previous screen
  // if the user is in the courseFinancingReviewScreen(between paymentSelection and captcha), it will redirect to paymentSelection
  if (typeof window !== 'undefined' && router) {
    router.beforePopState((cb) => {
      if (router.pathname !== '/checkout/[slug]') {
        router.push(changeRouteKeepParams(router.asPath, `/checkout/${router.query.slug || ''}`))
        return
      }

      if (progressBar?.previousScreenUniqueName === null) {
        router.push(changeRouteKeepParams(router.asPath, `/courses/${router.query.slug || ''}`))
        return
      }

      if (chosenPaymentMethod === 'CourseFinancing' && isInCourseFinancingReviewScreen) {
        setIsInCourseFinancingReviewScreen(false)
        return
      }

      if (progressBar?.previousScreenUniqueName && progressBar?.canBack === true) {
        setStep(progressBar.previousScreenUniqueName)
        return false
      }

      return true
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchPaymentInfo = (url: string) => {
    // 'classes' is undefined when the user comes from validateOtp screen and you don't have a cart yet
    // when you have an empty cart, 'classes' is just empty and not undefined
    if (url.includes('classes=undefined')) {
      return null
    }

    if (step === 'paymentSelection' && router.route === '/checkout/[slug]') {
      setIsPaymentInfoLoading(true)
      return readPaymentInfo()
    }
  }

  useSWR(`/paymentSelection?step=${step}&classes=${router.query.classes}&route=${router.route}`, fetchPaymentInfo, {
    onSuccess: (data) => {
      typeof data != 'string' && setPaymentInformationData(data)

      setIsPaymentInfoLoading(false)
    },
    onError: () => {
      setIsPaymentInfoLoading(false)
    },
    dedupingInterval: 2000,
    revalidateOnFocus: false,
  })

  return (
    <CheckoutContext.Provider
      value={{
        step,
        setStep,
        basicInfoData,
        setBasicInfoData,
        chosenPaymentMethod,
        setChosenPaymentMethod,
        paymentInformationData,
        setPaymentInformationData,
        setIsChecked,
        isChecked,
        verificationInfo,
        setVerificationInfo,
        isInCourseFinancingReviewScreen,
        setIsInCourseFinancingReviewScreen,
        selectedPartnerConditionId,
        setSelectedPartnerConditionId,
        chosenCourseFinancingInstallment,
        isLoadingData,
        setIsLoadingData,
        checkoutSuccessObject,
        setCheckoutSuccessObject,
        progressBar,
        setProgressBar,
        setExistingRequestsData,
        existingRequestsData,
        isSendingData,
        setIsSendingData,
        isPaymentInfoLoading,
        setIsPaymentInfoLoading,
        isRedirecting,
        setIsRedirecting,
        selectedUpfront,
        setSelectedUpfront,
        partnerId,
        setPartnerId,
        isHeaderCheckoutShown,
        alreadyHasActiveProductType,
        setAlreadyHasActiveProductType,
        inPageError,
        setInPageError,
        clientIpAddress,
        setClientIpAddress,
        clientUserAgent,
        setClientUserAgent,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}
