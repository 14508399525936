import { ButtonV3 as Button, InputFieldV3 as Input, TextButton } from '@provi/provi-components'
import { ChangeEvent, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import AgreeWithTos from '~/components/molecules/AgreeWithTos'
import { ROOT_URL } from '~/constants/index'
import { masks } from '~/enums/masks'
import { usePreRegistration } from './hooks'
import * as S from './style'
import { useAnalytics } from '~/hooks/useAnalytics'
interface IFormBasicInfo {
  title?: string
  subTitle: string
  isButtonGroup?: boolean
  variantButtonText?: string
}

const PreRegistrationForm = ({ title, subTitle, variantButtonText, isButtonGroup = false }: IFormBasicInfo) => {
  const recaptchaRef = useRef(null)
  const { sendEvent } = useAnalytics()

  const {
    values,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    handleEnterKey,
    validateForm,
    setFieldTouched,
    isChecked,
    setIsChecked,
    isSendingData,
    handleClickButtonVariant,
    onRecaptchaChange,
    isCaptchaValid,
  } = usePreRegistration()

  return (
    <S.WrapperForm>
      {title && <S.TitleForm>{title}</S.TitleForm>}
      <S.SubTitleForm fontSizeVariant={!!title}>{subTitle}</S.SubTitleForm>
      <S.WrapperInput>
        <Input
          inputMode="numeric"
          pattern="[0-9]*"
          type="text"
          label="CPF"
          placeholder="816.799.670-02"
          mask={masks.cpf}
          width="368px"
          value={values?.cpf || ''}
          name="cpf"
          onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue('cpf', e.target.value)}
          onBlur={() => {
            setFieldTouched('cpf')
            validateForm()
          }}
          isValid={!errors.cpf && touched.cpf}
          hasError={errors.cpf && touched.cpf}
          errorMessage={errors.cpf}
          onKeyDown={handleEnterKey}
        />

        <AgreeWithTos
          onChange={() => setIsChecked((prev) => !prev)}
          isChecked={isChecked}
          isDisabled={!(isValid && dirty)}
          margin={isButtonGroup ? '10px 0 77px' : '10px 0 138px'}
        />

        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          hl="pt-BR"
          sitekey={ROOT_URL.RECAPTCHA_V3_KEY}
          onChange={onRecaptchaChange}
        />

        {/* fallback if user might be a robot */}
        {isChecked && !isCaptchaValid && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="small"
            hl="pt-BR"
            sitekey={ROOT_URL.RECAPTCHA_V2_KEY}
            onChange={onRecaptchaChange}
          />
        )}

        {isButtonGroup ? (
          <S.ButtonWrapper displayFlex={isButtonGroup}>
            <TextButton
              text={variantButtonText || 'Conhecer a Principia'}
              type="button"
              onClick={handleClickButtonVariant}
              isLoading={isSendingData}
            />
            <Button
              text="Recuperar um pedido"
              type="submit"
              onClick={handleSubmit}
              disabled={!(isValid && dirty && isChecked && isCaptchaValid)}
              isLoading={isSendingData}
            />
          </S.ButtonWrapper>
        ) : (
          <S.ButtonWrapper>
            <Button
              text="Continuar"
              type="submit"
              icon
              onClick={() => {
                if (isValid && dirty && isChecked && isCaptchaValid) {
                  handleSubmit()

                  sendEvent('InitiateCheckout', {})
                }
              }}
              disabled={!(isValid && dirty && isChecked && isCaptchaValid)}
              isLoading={isSendingData}
            />
          </S.ButtonWrapper>
        )}
      </S.WrapperInput>
    </S.WrapperForm>
  )
}

export default PreRegistrationForm
