import crypto from 'crypto'

/**
 * Generates a sha256 hash of a string.
 *
 * @param {string} value - The value to be hashed.
 * @returns {string} - The hash in hexadecimal format.
 */

export function generateHash(value: string): string {
  if (!value || typeof value !== 'string') {
    throw new Error('O valor fornecido deve ser uma string válida.')
  }
  return crypto.createHash('sha256').update(value.trim().toLowerCase()).digest('hex')
}
