import { useEffect, useMemo, useState } from 'react'
import { textsMethodCreditCard, textsMethodPix, textsMethodTicket } from '~/enums/textsPaymentMethodTicket'
import CheckoutSuccess from '../../CheckoutSuccess'
import { useCheckoutSuccessByMethod } from './hooks'
import { useAnalytics } from '~/hooks/useAnalytics'

const CheckoutSuccessByMethod = () => {
  const { chosenPaymentMethod, checkoutSuccessObject, basicInfoData } = useCheckoutSuccessByMethod()
  const isPaid = checkoutSuccessObject?.paymentInfo?.status === 'paid' || false
  const { sendEvent } = useAnalytics()
  const [hasSentEvent, setHasSentEvent] = useState(false)
  const paidTitleText = isPaid ? 'O pagamento foi confirmado!' : ''

  const renderCheckoutSuccess = useMemo(() => {
    switch (chosenPaymentMethod) {
      case 'Boleto':
        return (
          <CheckoutSuccess
            isSuccess
            text={textsMethodTicket.success.text}
            title={paidTitleText || textsMethodTicket.success.title}
            buttonText="Visualizar boleto"
            isWithIcon
            isTicket
          />
        )
      case 'Pix':
        return (
          <CheckoutSuccess
            isSuccess
            text={textsMethodPix.success.text}
            title={paidTitleText || textsMethodPix.success.title}
            isPix
          />
        )
      case 'Cartão de crédito':
      case 'CreditCard':
      default:
        return (
          <CheckoutSuccess isSuccess text={textsMethodCreditCard.success.text} title={textsMethodCreditCard.success.title} />
        )
    }
  }, [chosenPaymentMethod, paidTitleText])

  useEffect(() => {
    if (!hasSentEvent) {
      sendEvent('Purchase', { ...basicInfoData, paymentMethod: chosenPaymentMethod, paymentStatus: isPaid })
      setHasSentEvent(true)
    }
  }, [hasSentEvent, basicInfoData, sendEvent, chosenPaymentMethod, isPaid])

  return renderCheckoutSuccess || null
}

export default CheckoutSuccessByMethod
